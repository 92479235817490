"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const Constants_1 = require("../../../utils/Constants");
const AssetTable_1 = require("./AssetTable");
const SpectraItem_1 = require("../../mui_components/SpectraItem");
const SpectraGridContainer_1 = require("../../mui_components/SpectraGridContainer");
const SpectraBox_1 = require("../../mui_components/SpectraBox");
const services_1 = require("../../../store/maps/services");
const MOUNTING_TYPES = {
    0: '',
    1: 'roe',
    2: 'smb',
    3: 'aerial',
};
const react_csv_1 = require("react-csv");
const Data = () => {
    const csvLink = (0, react_1.useRef)();
    const [tableData, setTableData] = (0, react_1.useState)([]);
    const [downloadData, setDownloadData] = (0, react_1.useState)([]);
    const [trigger, result] = (0, services_1.useLazyGetActivitiesQuery)();
    const [page, setPage] = (0, react_1.useState)(0);
    const [rowsPerPage, setRowsPerPage] = (0, react_1.useState)(10);
    const [tab, setTab] = (0, react_1.useState)(0);
    const [tableInfo, setTableInfo] = (0, react_1.useState)({});
    const getData = async (pageNo = page, rowsPerPageNo = rowsPerPage, tabIndex = tab) => {
        let url = `page=${pageNo + 1}&rows_per_page=${rowsPerPageNo}&mounting_type=${MOUNTING_TYPES[tabIndex]}`;
        try {
            let response = await trigger(url).unwrap();
            setTableData(response.items);
            setTableInfo(response);
        }
        catch (error) {
            console.log(error);
            setTableData([]);
        }
    };
    const changePage = async (pageNo) => {
        setPage(pageNo);
        getData(pageNo, rowsPerPage, tab);
    };
    const changeRowsPerPage = async (totalRows) => {
        setRowsPerPage(totalRows);
        getData(page, totalRows, tab);
    };
    const changeTab = async (index) => {
        setTab(index);
        getData(page, rowsPerPage, index);
    };
    (0, react_1.useEffect)(() => {
        getData();
    }, [tableData, tableInfo]);
    const getDataForDownload = async () => {
        let url = `rows_per_page=10000&mounting_type=${MOUNTING_TYPES[tab]}`;
        try {
            let response = await trigger(url).unwrap();
            setDownloadData(response.items);
        }
        catch (error) {
            console.log(error);
            setDownloadData([]);
        }
        csvLink.current.link.click();
        setDownloadData([]);
    };
    const csvHeaders = [
        { label: 'Asset Name', key: 'asset_name' },
        { label: 'Site ID', key: 'site_id' },
        { label: 'eNB/gNb ID', key: 'enbgnb_id' },
        { label: 'Structure Type', key: 'asset_type' },
        { label: 'Site Status', key: 'asset_status' },
        { label: 'Deployment Type', key: 'deployment_type' },
        { label: 'Latitude', key: 'latitude' },
        { label: 'Longitude', key: 'longitude' },
        { label: 'Structure Height', key: 'antenna_height' },
        { label: 'Serial No.', key: 'serial_no' },
        { label: 'Region/Market Name', key: 'region_market_name' },
    ];
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(SpectraBox_1.SpectraBox, { styles: {
                minHeight: tableData.length > 0 ? null : '100vh',
                pt: 13,
                '& .MuiBox-root': {
                    ...Constants_1.Constants.scrollBar,
                },
            } },
            react_1.default.createElement(react_csv_1.CSVLink, { data: downloadData, headers: csvHeaders, filename: `${new Date().toJSON().slice(0, 10)}.csv`, ref: csvLink }, ''),
            react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null,
                react_1.default.createElement(SpectraItem_1.SpectraItem, { xs: 12, sm: 12, md: 12, lg: 12 },
                    react_1.default.createElement(AssetTable_1.AssetTable, { changePage: changePage, changeRowsPerPage: changeRowsPerPage, changeTab: changeTab, getDataForDownload: getDataForDownload, page: page, rowsPerPage: rowsPerPage, info: tableInfo, data: tableData }))))));
};
exports.default = Data;

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpectraNetworkAutocomplete = void 0;
const react_1 = __importStar(require("react"));
const material_1 = require("@mui/material");
const Constants_1 = require("../../../utils/Constants");
const FontStyles_1 = require("../../../utils/Constants/FontStyles");
exports.SpectraNetworkAutocomplete = (0, react_1.forwardRef)(({ list, label, label2, placeholder, icon, value, onChange, children, style, inpStyles, clearIcon, selected, onClickLabel0, onClickLabel1, getOptionLabel, filterOption, onOpen, onClose, onChangeInput, }, ref) => {
    const [open, setOpen] = (0, react_1.useState)(false);
    const show = (val) => {
        setOpen(val);
    };
    (0, react_1.useImperativeHandle)(ref, () => ({ show }));
    return (react_1.default.createElement(material_1.Autocomplete, { open: open, onOpen: onOpen, onClose: onClose, onChange: onChange, filterOptions: filterOption, fullWidth: true, sx: { mb: 0.2, fontFamily: FontStyles_1.Font.roboto, ...style }, disablePortal: true, getOptionLabel: getOptionLabel, clearIcon: clearIcon, freeSolo: true, options: list, renderOption: children, renderInput: (params) => (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(material_1.Box, { sx: { flexDirection: 'row', display: 'flex' } },
                react_1.default.createElement(material_1.FormHelperText, { onClick: onClickLabel0, style: {
                        fontSize: 12,
                        fontWeight: '500',
                        fontFamily: FontStyles_1.Font.roboto,
                        color: !selected ? Constants_1.Colors.BLUE : Constants_1.Colors.LIGHTGREY,
                        marginLeft: 5,
                        cursor: 'pointer',
                    } }, label),
                react_1.default.createElement(material_1.FormHelperText, { onClick: onClickLabel1, style: {
                        fontSize: 12,
                        fontWeight: '500',
                        fontFamily: FontStyles_1.Font.roboto,
                        color: selected ? Constants_1.Colors.BLUE : Constants_1.Colors.LIGHTGREY,
                        marginLeft: 35,
                        cursor: 'pointer',
                    } }, label2)),
            react_1.default.createElement(material_1.TextField, { ...params, onChange: onChangeInput, value: value, placeholder: placeholder, InputProps: {
                    ...params.InputProps,
                    startAdornment: (react_1.default.createElement(material_1.InputAdornment, { component: "div", style: { paddingLeft: -5 }, position: "start" },
                        react_1.default.createElement("div", { style: {
                                marginLeft: -2,
                                paddingTop: 13,
                                padding: 2,
                                width: 10,
                                color: Constants_1.Colors.DELTA,
                            } }, icon))),
                }, sx: {
                    fontFamily: FontStyles_1.Font.roboto,
                    '& legend': { display: 'none' },
                    '& fieldset': { top: 0 },
                    borderRadius: 4,
                    '& .MuiAutocomplete-inputRoot': {
                        borderRadius: 2,
                        borderColor: Constants_1.Colors.DISABLED_GREY,
                    },
                    '& .MuiInputBase-root': {
                        height: 43,
                        borderColor: Constants_1.Colors.DISABLED_GREY,
                    },
                    '& .MuiOutlinedInput-root': {
                        paddingTop: 0.5,
                    },
                    ...inpStyles,
                } }))) }));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lineLayer = exports.unclusteredSelectablePointLayer = exports.unclusteredPointLayerAP = exports.unclusteredPointLayer = exports.clusterCountLayer = exports.clusterLayer = void 0;
const Constants_1 = require("../../../utils/Constants");
exports.clusterLayer = {
    id: 'clusters',
    type: 'circle',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    paint: {
        'circle-color': [
            'step',
            ['get', 'point_count'],
            '#51bbd6',
            100,
            '#f1f075',
            750,
            '#f28cb1',
        ],
        'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
};
exports.clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'earthquakes',
    filter: ['has', 'point_count'],
    layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12,
    },
};
exports.unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'earthquakes',
    filter: ['==', 'asset_type', 1],
    paint: {
        'circle-color': [
            'match',
            ['get', 'asset_status'],
            'FIELD_TECH',
            Constants_1.Colors.AZURE_RADIANCE,
            'Cloud',
            Constants_1.Colors.NEON_CARROT,
            'SAS',
            Constants_1.Colors.CARIBBEAN_GREEN,
            'RMA',
            Constants_1.Colors.ALIZARIN_CRIMSON,
            Constants_1.Colors.LIGHTGREY,
        ],
        'circle-radius': 7,
        'circle-stroke-width': 2,
        'circle-stroke-color': Constants_1.Colors.WHITE,
    },
};
exports.unclusteredPointLayerAP = {
    id: 'unclustered-pointAP',
    type: 'symbol',
    source: 'earthquakes1',
    layout: {
        'icon-image': [
            'match',
            ['get', 'number_of_sectors'],
            0,
            '1-sector',
            1,
            '1-sector',
            2,
            '2-sector',
            3,
            '3-sector',
            4,
            '4-sector',
            5,
            '5-sector',
            6,
            '6-sector',
            '6-sector',
        ],
        'icon-size': ['match', ['get', 'number_of_sectors'], 3, 0.9, 6, 0.9, 0.07],
        'text-field': ['get', 'tracking_id'],
        'text-radial-offset': 1.5,
        'text-size': 12,
        'text-variable-anchor': ['top'],
    },
    filter: ['==', 'asset_type', 0],
};
exports.unclusteredSelectablePointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'earthquakes',
    filter: ['==', 'asset_type', 1,],
    paint: {
        'circle-color': [
            'match',
            ['get', 'selected'],
            'true',
            '#64bdbb',
            '#888888',
        ],
        'circle-radius': 7,
        'circle-stroke-width': 2,
        'circle-stroke-color': Constants_1.Colors.WHITE,
    },
};
exports.lineLayer = {
    id: 'route',
    type: 'line',
    source: 'route',
    layout: {
        'line-join': 'miter',
        'line-cap': 'square',
    },
    paint: {
        'line-color': Constants_1.Colors.Orange,
        'line-width': 4,
        'line-dasharray': [2, 2],
    },
};

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityTable = void 0;
const react_1 = __importStar(require("react"));
const Constants_1 = require("../../../utils/Constants");
const AssetDetailModal_1 = require("./AssetDetailModal");
const SpectraBox_1 = require("../../mui_components/SpectraBox");
const SpectraGridContainer_1 = require("../../mui_components/SpectraGridContainer");
const SpectraItem_1 = require("../../mui_components/SpectraItem");
const SpectraSubheading3_1 = require("../../mui_components/SpectraSubheading3");
const SpectraTable_1 = require("../../mui_components/SpectraTable");
const material_1 = require("@mui/material");
const SpectraCircle_1 = require("../../mui_components/SpectraCircle");
const services_1 = require("../../../store/maps/services");
const SpectraTablePagination_1 = require("../../mui_components/SpectraTablePagination");
const SpectraTableRow_1 = require("../../mui_components/SpectraTableRow");
const ActivityTable = ({ data, changePage, page, changeRowsPerPage, rowsPerPage, setTableData, setTableInfo, totalAssets, currentTab, setTotalAssets, filter, filterSearch, searchInput, }) => {
    const [tab, setTab] = (0, react_1.useState)(0);
    const modalRef = (0, react_1.useRef)();
    const [currentData, setCurrentData] = (0, react_1.useState)([]);
    const numberOfTabs = ['Cloud', 'SAS', 'RMA'];
    const [trigger, result] = (0, services_1.useLazyGetActivitiesQuery)();
    const handleTabTotals = (arr) => {
        const newArray = totalAssets.map((i, index) => {
            if (arr[index] !== 0 && arr[index] != i) {
                return arr[index];
            }
            return i;
        });
        setTotalAssets(newArray);
    };
    const changeTab = async (val, index) => {
        try {
            const response = await trigger(`status=${val}`).unwrap();
            setTableData(response.items);
            let temp = response.status_count;
            let tempArray = [
                temp?.Cloud ? temp.Cloud : 0,
                temp?.SAS ? temp.SAS : 0,
                temp?.RMA ? temp.RMA : 0,
            ];
            handleTabTotals(tempArray);
            setTableInfo(response);
            setTab(index);
            changePage(0);
        }
        catch (error) { }
    };
    (0, react_1.useEffect)(() => {
        setTab(currentTab);
        changePage(0);
    }, [currentTab]);
    const renderTabs = (title, index) => (react_1.default.createElement(SpectraItem_1.SpectraItem, { key: index, xs: 4, sm: 4, md: 4, lg: 4 },
        react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null,
            react_1.default.createElement(SpectraItem_1.SpectraItem, { xs: 12, sm: 12, md: 10, lg: 10 },
                react_1.default.createElement(SpectraBox_1.SpectraBox, { onClick: () => {
                        if (searchInput.length > 0) {
                            setTab(index);
                            changePage(0);
                        }
                        else {
                            filter
                                ? filterSearch(!index ? 'Cloud' : index == 1 ? 'SAS' : 'RMA', index)
                                : changeTab(!index ? 'Cloud' : index == 1 ? 'SAS' : 'RMA', index);
                        }
                    }, styles: {
                        backgroundColor: Constants_1.Colors.TRANSPARENT,
                        borderColor: Constants_1.Colors.TRANSPARENT,
                        borderBottomWidth: 3,
                        borderBottomColor: tab != index ? Constants_1.Colors.TRANSPARENT : Constants_1.Colors.BLUE,
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        p: 0,
                        pb: 0.5,
                        cursor: 'pointer',
                    } },
                    react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null,
                        react_1.default.createElement(SpectraItem_1.SpectraItem, { xs: 12, sm: 12, md: 12, lg: 12, display: "flex", justifyContent: "space-around" },
                            react_1.default.createElement(SpectraSubheading3_1.SpectraSubheading3, { align: "center", styles: {
                                    marginTop: 5,
                                    color: tab == index ? Constants_1.Colors.BLUE : Constants_1.Colors.LIGHTGREY,
                                }, text: title }),
                            react_1.default.createElement(SpectraCircle_1.SpectraCircle, { styles: {
                                    backgroundColor: Constants_1.Colors.REGENT_ST_BLUE,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                } },
                                react_1.default.createElement(SpectraSubheading3_1.SpectraSubheading3, { align: "right", styles: {
                                        textAlign: 'center',
                                        color: Constants_1.Colors.BLUE,
                                    }, text: `${totalAssets[index]}` })))))),
            react_1.default.createElement(SpectraItem_1.SpectraItem, { xs: 0, sm: 0, md: 2, lg: 2, display: "flex" },
                react_1.default.createElement(material_1.Divider, { orientation: "vertical", style: {
                        minHeight: '5vh',
                        borderRightWidth: '1.2px',
                        maxWidth: 0,
                        width: '100%',
                        borderColor: Constants_1.Colors.TRANSPARENT,
                        borderRightColor: Constants_1.Colors.ALTO,
                        borderStyle: 'dashed',
                    } })))));
    const tableTitles = [
        { name: 'Tracking ID' },
        { name: 'CBRS Node Name' },
        { name: 'Site ID' },
        { name: 'Mounting Type' },
        { name: 'CBSD Category' },
        { name: 'Last Modified' },
        { name: 'Age' },
    ];
    const filterData = () => {
        if (data) {
            setCurrentData(data.filter((item) => !tab
                ? item.asset_status == 'Cloud'
                : tab == 1
                    ? item.asset_status == 'SAS'
                    : item.asset_status == 'RMA'));
        }
    };
    (0, react_1.useEffect)(() => {
        filterData();
    }, [data, tab, page]);
    const changingPage = async (newPage) => {
        console.log(`status=${!currentTab ? 'Cloud' : currentTab == 1 ? 'SAS' : 'RMA'}&page=${newPage + 1}`);
        try {
            const response = await trigger(`status=${tab == 0 ? 'Cloud' : tab == 1 ? 'SAS' : tab == 2 ? 'RMA' : 'Cloud'}&page=${newPage + 1}`).unwrap();
            setTableData(response.items);
            changePage(newPage);
        }
        catch (error) {
            console.log(error);
        }
    };
    const handleChangePage = (event, newPage) => {
        changingPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        changeRowsPerPage(parseInt(event.target.value, 10));
    };
    const rowCells = (row, index) => {
        const oneDay = 24 * 60 * 60 * 1000;
        const firstDate = new Date(row.created_at);
        const secondDate = new Date();
        const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(SpectraTableRow_1.TableRow, { onClick: () => modalRef.current.getParameter(row), style: {
                    '&:hover': {
                        color: Constants_1.Colors.BLUE,
                        textDecoration: 'underline',
                    },
                } }, row.tracking_id),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, row.asset_name),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, row.site_id),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, row.deployment_type),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, row.category),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, row.updated_at.substring(0, 10)),
            react_1.default.createElement(SpectraTableRow_1.TableRow, null, diffDays)));
    };
    return (react_1.default.createElement(material_1.Paper, { sx: {
            width: '100%',
            overflow: 'hidden',
            boxShadow: 3,
            borderRadius: 2,
        } },
        react_1.default.createElement(SpectraBox_1.SpectraBox, { styles: {
                width: '100%',
                bgcolor: Constants_1.Colors.TRANSPARENT,
                mt: 0,
                borderRadius: 2,
            } },
            react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null,
                react_1.default.createElement(SpectraItem_1.SpectraItem, { display: "flex", xs: 5, sm: 5, md: 5, lg: 5 },
                    react_1.default.createElement(SpectraGridContainer_1.SpectraGridContainer, null, numberOfTabs.map((item, index) => renderTabs(item, index)))),
                react_1.default.createElement(SpectraItem_1.SpectraItem, { xs: 7, sm: 7, md: 7, lg: 7, display: "flex", justifyContent: "flex-end" },
                    react_1.default.createElement(SpectraTablePagination_1.CustomTablePagination, { count: totalAssets[tab], style: {
                            borderBottomColor: Constants_1.Colors.TRANSPARENT,
                            alignSelf: 'flex-end',
                            marginRight: 30,
                        }, page: page, rowsPerPageOptions: [10, 25, 100], rowsPerPage: rowsPerPage, handlePageChange: handleChangePage, handleChangeRowsPerPage: handleChangeRowsPerPage })))),
        data.length > 0 ? (react_1.default.createElement(SpectraTable_1.SpectraTable, { columns: tableTitles, rows: currentData, rowCells: rowCells })) : null,
        react_1.default.createElement(AssetDetailModal_1.AssetDetailModal, { ref: modalRef })));
};
exports.ActivityTable = ActivityTable;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteExtraIcons = exports.filterImage = exports.setOtherImage = exports.setIcon = exports.ImageSlice = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    icon: [
        {
            id: 0,
            document_id: 0,
            file: {},
        },
        {
            id: 1,
            document_id: 0,
            file: {},
        },
        {
            id: 2,
            document_id: 0,
            file: {},
        },
        {
            id: 3,
            document_id: 0,
            file: {},
        },
        {
            id: 4,
            document_id: 0,
            file: {},
        },
    ],
};
exports.ImageSlice = (0, toolkit_1.createSlice)({
    name: 'image',
    initialState,
    reducers: {
        setIcon: (state, action) => {
            state.icon[action.payload.index].id = action.payload.index;
            state.icon[action.payload.index].document_id = action.payload.document_id;
            state.icon[action.payload.index].file = action.payload.file;
        },
        setOtherImage: (state, action) => {
            state.icon.push(action.payload);
        },
        filterImage: (state, action) => {
            state.icon = state.icon.filter((item, index) => index != action.payload);
        },
        deleteExtraIcons: (state) => {
            state.icon = state.icon.filter((item, index) => (item.id <= 4));
        },
    },
});
_a = exports.ImageSlice.actions, exports.setIcon = _a.setIcon, exports.setOtherImage = _a.setOtherImage, exports.filterImage = _a.filterImage, exports.deleteExtraIcons = _a.deleteExtraIcons;
exports.default = exports.ImageSlice.reducer;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_map_gl_1 = require("react-map-gl");
const MapImage = () => {
    const { current: map } = (0, react_map_gl_1.useMap)();
    if (!map.hasImage('1-sector')) {
        map.loadImage(require('../../../../assets/images/1-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('1-sector'))
                map.addImage('1-sector', image);
        });
    }
    if (!map.hasImage('2-sector')) {
        map.loadImage(require('../../../../assets/images/2-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('2-sector'))
                map.addImage('2-sector', image);
        });
    }
    if (!map.hasImage('3-sector')) {
        map.loadImage(require('../../../../assets/images/3-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('3-sector'))
                map.addImage('3-sector', image);
        });
    }
    if (!map.hasImage('4-sector')) {
        map.loadImage(require('../../../../assets/images/4-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('4-sector'))
                map.addImage('4-sector', image);
        });
    }
    if (!map.hasImage('5-sector')) {
        map.loadImage(require('../../../../assets/images/5-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('5-sector'))
                map.addImage('5-sector', image);
        });
    }
    if (!map.hasImage('6-sector')) {
        map.loadImage(require('../../../../assets/images/6-sector.png'), (error, image) => {
            if (error)
                throw error;
            if (!map.hasImage('6-sector'))
                map.addImage('6-sector', image);
        });
    }
    return null;
};
exports.default = MapImage;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRefreshTokenMutation = exports.useForgetPasswordMutation = exports.useSignUpUserMutation = exports.useLoginUserMutation = exports.authServices = void 0;
// @ts-nocheck
const react_1 = require("@reduxjs/toolkit/query/react");
const config_1 = require("../../../utils/config");
exports.authServices = (0, react_1.createApi)({
    reducerPath: 'Authentication',
    baseQuery: (0, react_1.fetchBaseQuery)({
        baseUrl: `${config_1.APP_URL}api/v1/user`,
        // prepareHeaders: async (headers, { getState }) => {
        //   const token = await AsyncStorage.getItem(ACCESS_TOKEN);
        //   if (token) {
        //     headers.set('Authorization', `Bearer ${token}`);
        //   }
        //   return headers;
        // },
    }),
    endpoints: (builder) => ({
        loginUser: builder.mutation({
            query: (body) => ({
                url: '/login',
                method: 'POST',
                body: body,
            }),
        }),
        signUpUser: builder.mutation({
            query: (body) => ({
                url: '/signup',
                method: 'POST',
                body: body,
            }),
        }),
        forgetPassword: builder.mutation({
            query: (body) => ({
                url: '/forgot-password',
                method: 'POST',
                body: body,
            }),
        }),
        refreshToken: builder.mutation({
            query: ({ refreshToken }) => ({
                url: '/refresh',
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${refreshToken}`
                }
            })
        })
    }),
});
exports.useLoginUserMutation = exports.authServices.useLoginUserMutation, exports.useSignUpUserMutation = exports.authServices.useSignUpUserMutation, exports.useForgetPasswordMutation = exports.authServices.useForgetPasswordMutation, exports.useRefreshTokenMutation = exports.authServices.useRefreshTokenMutation;
